<!--
 * @Description: 人行道停车运营管理 运营管理 场内车辆  siteLayOut
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌号">
              <el-input v-model="searchForm.plateNumber"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="车牌颜色">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.numberPlateColorCode"
                         placeholder="全部">
                <el-option v-for="(item, index) in colorList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="入场时间">
              <el-date-picker v-model="inTime"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="queryList">查 询</el-button>
          <el-button type="info"
                     style="margin-left:17px"
                     @click="resetForm">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>场内车辆信息</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="handleClickWatch(scope.row)"
                           type="text"
                           size="medium">查看</el-button>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 查看弹窗 -->
    <el-dialog title="场内车辆详情"
               :visible.sync="dialogVisible"
               append-to-body>
      <el-row class="line">
        <el-col :span="12">
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">车牌号 : {{ row.plateNumber}}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">车牌颜色 : {{ row.plateColor}}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">入场时间 : {{ row.inTime}}</el-row>
        </el-col>
        <el-col :span="12">
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">停车时长 : {{ row.parkDuration}} </el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">停车场名称 : {{ row.parkName}}</el-row>
          <el-row style="margin-top:10px;color:#79baf7;font-size:16px;">关联订单编号 : {{ row.orderSequence}}</el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import parkStopTime from '@/utils/parkStopTime'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      dialogVisible: false,
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      pageNum: 1,
      pageSize: 15,
      tableColumnList: [
        {
          prop: 'plateNumber',
          label: '车牌号',
        },
        {
          prop: 'plateColor',
          label: '车牌颜色',
        },
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'inTime',
          label: '入场时间',
        },
        {
          prop: 'parkDuration',
          label: '停车时长',
        },
      ],
      tableList: {
        list: [
        ],
      },
      searchForm: {},
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      inTime: null,//时间
      currentTime: new Date().Format("yyyy-MM-dd hh:mm:ss"),
      parkNameList: [],//停车场下拉
      colorList: [],//车牌颜色下拉
      row: []//行
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
    this.getQueryDict()
    this.getColorDict()
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      this.$axios.post(this.$downloadBaseUrl + "/parkRecord/onSiteVehiclesDownload", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }
      ).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "场内车辆.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 获取实时停车列表
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$siteLayOut.onSiteVehicles(this.searchForm).then(res => {
        res.resultEntity.content.forEach(item => {
          item.parkDuration = parkStopTime(this.currentTime, item.inTime)
        });
        this.tableList.list = res.resultEntity.content
        this.paginationConfig.total = res.resultEntity.totalElements
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    // 查询
    queryList () {
      this.listMap.pageNum = 1
      this.pageNum = 1
      if (this.inTime) {
        this.searchForm.inStartTime = this.inTime[0]
        this.searchForm.inEndTime = this.inTime[1]
      } else {
        this.searchForm.inStartTime = ''
        this.searchForm.inEndTime = ''
      }
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.searchForm = {}
      this.inTime = null
      this.listMap.pageNum = 1
      this.pageNum = 1
      this.queryTableList()
    },
    // 操作中的查看按钮
    handleClickWatch (row) {
      this.row = row
      this.dialogVisible = true
    },
    // 获取停车场名称
    getQueryDict () {
      let info = {
        columnName: ['park_id', 'park_name'], //有且只有两个 id放前面名称放后面
        tableName: 'tb_park',
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": '2'
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
    //获取车牌颜色
    getColorDict () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '204E657017184ADFAE6E39629B38DDC2',
          },
        ]
      }
      this.$queryDict.queryDict(info).then((response) => {
        this.colorList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 130px;
//@import url(); 引入公共css类
// 最外层div
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
</style>

